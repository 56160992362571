import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_UIFormMessage = _resolveComponent("UIFormMessage")
  const _component_ProfessionTiles = _resolveComponent("ProfessionTiles")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_UIFormMessage, {
      message: _ctx.formMessage,
      type: _ctx.formMessageType,
      onCloseClicked: $options.clearFormMessage
    }, null, 8, ["message", "type", "onCloseClicked"]),
    _createVNode(_component_ProfessionTiles, { onProfessionSelected: $options.navigate }, null, 8, ["onProfessionSelected"])
  ]))
}