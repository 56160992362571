
import PDEIcon from '@/components/pde/PDEIcon.vue'

export default {
  components: {
    PDEIcon,
  },
  props: {
    message: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    customContent: {
      type: Boolean,
      default: false,
    },
    canClose: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['close-clicked'],
  data() {
    return {
      formMessage: this.message,
      formMessageType: this.type || 'success',
    }
  },
  watch: {
    message(newMessage) {
      let doFocus = false

      if (this.formMessage === '' && newMessage !== '') {
        doFocus = true
      }

      this.formMessage = newMessage

      if (doFocus) {
        this.$nextTick(() => {
          this.$refs.closeButton.focus()
        })
      }
    },
    type(newType) {
      this.formMessageType = newType
    },
  },
  methods: {
    close() {
      this.$emit('close-clicked')
    },
  },
}
